import { lazy } from "react";

export default [
  // {
  //   title: "Top Book Appointment",
  //   component: lazy(() => import("./TopBookAppointmentPage")),
  //   url: "/top/appointment",
  //   requirePermission: true,
  //   roles: ["Super_Admin", "Admin", "Client"],
  // },
  {
    title: "Top Book Appointment",
    component: lazy(() => import("./TopBookAppointmentPage")),
    url: "/top/appointment/:id/:shareId",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Counselling appointment",
    component: lazy(() => import("./CounsellingAppointment")),
    url: "/top/appointment/counselling",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: 'Top Booking Confirmed',
    component: lazy(() => import('./TopBookingConfirmed')),
    url: '/top/booking-confirmed/:id/:shareId',
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: 'Top Booking Confirmed',
    component: lazy(() => import('./TopCousellingAppointment')),
    url: '/top/counselling/:id/:shareId',
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: 'Top Abortion Contraception',
    component: lazy(() => import('./TopAbortionCallBack')),
    url: '/top/post-abortion-contraception/:id/:shareId',
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
];