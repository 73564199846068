import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TOKEN_KEY,
  setCookie,
  getCookie,
  removeCookie,
  decodeUserInfo,
} from "../utils/useCookie";

const userDetailsObj = (token: string) => {
  const jwtDecodeInfo: any = decodeUserInfo(token);
  return jwtDecodeInfo;
};

const userAccessToken = getCookie(TOKEN_KEY) || "";

const userInfoObj = userDetailsObj(userAccessToken);
interface IUserDetails {
  usertype: string | null;
}

interface ITwoFactorAuth {
  email: string;
  identifier: string;
  token: string;
}
interface IResetTwoFactorAuth {
  email: string;
  token: string;
}
interface InterfaceAuth {
  name?: string;
  userLoginEmailId?: string;
  accessToken?: string;
  emailIsSafe?: boolean;
  phoneIsSafe?: boolean;
  pin?: boolean;
  userInfo?: IUserDetails;
  twoFactorAuth?: ITwoFactorAuth;
  resetTwoFactorAuth?: IResetTwoFactorAuth;
}

interface IAuthPayload {
  data: {
    accessToken: string;
    emailIsSafe?: boolean;
    phoneIsSafe?: boolean;
    pin?: boolean;
  };
}

const twoFactorAuthPayload: ITwoFactorAuth = {
  email: "",
  identifier: "",
  token: "",
};

const resetTwoFactorAuthPayload: IResetTwoFactorAuth = {
  token: "",
  email: "",
};

const initialState: InterfaceAuth = {
  name: "",
  userLoginEmailId: "",
  accessToken: userAccessToken,
  emailIsSafe: false,
  phoneIsSafe: false,
  pin: false,
  userInfo: userInfoObj,
  twoFactorAuth: twoFactorAuthPayload,
  resetTwoFactorAuth: resetTwoFactorAuthPayload,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Store the user's login email ID using a function called "setUserLogInEmailId"
    setUserLogInEmailId: (state, action: PayloadAction<string>) => {
      state.userLoginEmailId = action?.payload;
    },
    setVerifyAccountFromEmail: (
      state: any,
      action: PayloadAction<IAuthPayload>
    ) => {
      const { payload } = action;
      if (payload?.data.accessToken) {
        setCookie(TOKEN_KEY, payload?.data?.accessToken, { path: "/" });
        const accessToken = payload?.data?.accessToken;
        const jwtDecodeInfo: any = decodeUserInfo(accessToken);
        state.userInfo.usertype = jwtDecodeInfo?.usertype;
      }
    },
    setUserLogIn: (state: any, action: PayloadAction<IAuthPayload>) => {
      const { payload } = action;
      console.log("🚀 ~ file: authSlice.ts:79 ~ payload:", payload);
      state.accessToken = payload?.data?.accessToken;
      state.emailIsSafe = payload?.data?.emailIsSafe || false;
      state.phoneIsSafe = payload?.data?.phoneIsSafe || false;
      state.pin = payload?.data?.pin || false;
      if (payload?.data?.accessToken) {
        setCookie(TOKEN_KEY, payload?.data?.accessToken, { path: "/" });
        const accessToken = payload?.data?.accessToken;
        const jwtDecodeInfo: any = decodeUserInfo(accessToken);
        state.userInfo.usertype = jwtDecodeInfo?.usertype;
      }
    },
    // Register an account and verify two-factor authentication before logging in.
    setTwoFactorAuthToken: (
      state: any,
      action: PayloadAction<ITwoFactorAuth>
    ) => {
      const { payload } = action;
      // console.log("🚀 ~ file: authSlice.ts:86 ~ payload:", payload)
      state.twoFactorAuth.token = payload?.token;
      state.twoFactorAuth.identifier = payload?.identifier;
      state.twoFactorAuth.email = payload?.email;
    },
    // Reset the password from the login page and store the reset token.
    setResetTwoFactorAuthToken: (
      state: any,
      action: PayloadAction<IResetTwoFactorAuth>
    ) => {
      const { payload } = action;
      console.log(
        "🚀 ~ file: authSlice.ts:86 ~ setResetTwoFactorAuthToken:",
        payload
      );
      state.resetTwoFactorAuth.token = payload?.token;
      state.resetTwoFactorAuth.email = payload?.email;
    },
    setSuperAdminUserLogIn: (state: any, action: PayloadAction<any>) => {
      const { payload } = action;
      state.accessToken = payload?.data?.accessToken;
      if (payload?.data?.accessToken) {
        setCookie(TOKEN_KEY, payload?.data?.accessToken, { path: "/" });
        const accessToken = payload?.data?.accessToken;
        const jwtDecodeInfo: any = decodeUserInfo(accessToken);
        state.userInfo.id = jwtDecodeInfo?.id;
        state.userInfo.usertype = jwtDecodeInfo?.usertype;
        state.userInfo.email = jwtDecodeInfo?.email;
        state.userInfo.userName = jwtDecodeInfo?.userName;
      }
    },
    resetAuthState: (state: any) => {
      (state.name = ""),
        (state.userLoginEmailId = ""),
        (state.accessToken = ""),
        (state.emailIsSafe = false),
        (state.phoneIsSafe = false),
        (state.pin = false),
        (state.userInfo = userDetailsObj("")),
        (state.twoFactorAuth = twoFactorAuthPayload),
        (state.resetTwoFactorAuth = resetTwoFactorAuthPayload);
    },
  },
});

export const {
  setUserLogIn,
  setVerifyAccountFromEmail,
  setTwoFactorAuthToken,
  setUserLogInEmailId,
  setResetTwoFactorAuthToken,
  setSuperAdminUserLogIn,
  resetAuthState,
} = authSlice.actions;

export default authSlice.reducer;
